import {
  AuthState,
  OktaAuthCoreInterface,
  OktaAuthOAuthInterface,
} from '@okta/okta-auth-js';
import logEvent, { LogEvent, LogEventStatus } from 'utils/logEvent';
import windowPostMessage from 'utils/windowPostMessage';

const baseConfig = {
  version: process.env.REACT_APP_VERSION,
};
const matchesReactAppTarget = (env: string) =>
  env.toLowerCase() === process.env.REACT_APP_TARGET?.toLowerCase();

export const matchesEnvironment = (env: string) =>
  process.env.REACT_APP_TARGET
    ? matchesReactAppTarget(env)
    : window.location.hostname.startsWith(env);

const baseOidcConfig = {
  transformAuthState: async (
    oktaAuth: OktaAuthCoreInterface,
    authState: AuthState,
  ): Promise<AuthState> => {
    if (!authState?.isAuthenticated) {
      return authState;
    }
    const sessionExists = await oktaAuth?.session?.exists();
    if (!sessionExists) {
      oktaAuth?.tokenManager?.clear();
    }

    return {
      ...authState,
      isAuthenticated: authState.isAuthenticated && sessionExists,
    };
  },
  restoreOriginalUri: async (oktaAuth: OktaAuthOAuthInterface, originalUri) => {
    let url;
    try {
      url = new URL(originalUri as string);
    } catch {
      url = new URL(window.location.origin);
    }
    const { authType } = JSON.parse(
      sessionStorage.getItem('loginData') || '{}',
    );
    const valid =
      /.*\.bandwidth\.com$/.test(url?.host) ||
      /.*\.voxbone\.com$/.test(url?.host) ||
      /^localhost:[0-9]*$/.test(url?.host);
    const isAuthenticated = await oktaAuth.isAuthenticated();

    if (!isAuthenticated) {
      return;
    }

    if (sessionStorage.getItem('idpr') === 'true') {
      const userinfo = await oktaAuth.token?.getUserInfo();
      logEvent(LogEvent.IdP, LogEventStatus.Successful, {
        username: userinfo?.preferred_username,
      });
      sessionStorage.removeItem('idpr');
    }
    if (authType === 'popup') {
      windowPostMessage({ isAuthenticated, originalUri });
      return;
    }

    if (
      url.href !== window.location.origin &&
      url.href !== window.location.href &&
      valid
    ) {
      window.location.replace(url.href);
    } else {
      window.location.replace(configValue.dashboard);
    }
  },
};

const configValue = { ...baseConfig, ...window._config };
configValue.oidc = { ...baseOidcConfig, ...window._config?.oidc };
export default configValue;
